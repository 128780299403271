/* Grid system*
//
/* Define your custom responsive grid.*/

/*  Number of columns in the grid.*/
@grid-columns:              12;
/* * Padding between columns. Gets divided in half for the left and right.*/
@grid-gutter-width:         30px;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

@font-family-sans-serif:  "Ubuntu", Helvetica, Arial, sans-serif;
@font-size-base:          18px;
@font-size-large:         ceil((@font-size-base * 1.3)); // ~23
@font-size-small:         ceil((@font-size-base * 0.85)); // ~15

@font-size-h1:            floor((@font-size-base * 2.6)); // 
@font-size-h2:            floor((@font-size-base * 2.0)); // 
@font-size-h3:            ceil((@font-size-base * 1.4)); // 
@font-size-h4:            ceil((@font-size-base * 1.25)); // ~23px
@font-size-h5:            @font-size-base;
@font-size-h6:            ceil((@font-size-base * 0.85)); // ~15px


// Single primary color.
@primary:        hsl(248, 60%, 10%);
@secondary-blue:    #406d80;
@secondary-green:   #40807e;
@secondary-purple:  #4c6080;

@secondary-blue-type:    #406d80;
@secondary-green-type:   #40807e;
@secondary-purple-type:  #4c6080;

// bootstrap colors
@brand-primary:         darken(#428bca, 6.5%); // #337ab7
@brand-success:         #5cb85c;
@brand-info:            #5bc0de;
@brand-warning:         #f0ad4e;
@brand-danger:          #d9534f;