.page-container{
    padding-left: 15px;
    padding-right: 15px;
}
/* START-Image / CLAIM -------------------------------------------------------------*/
.start_container{
    @media (min-width: @screen-sm-min) {
        height: 70vh;
    }
}
.full-width{
    padding: 0;
}
.header-plate{
    margin-top: 80px;
    color: white;
    background-color: @primary;
    padding-top: 30px;
    padding-bottom: 20px;
    ul {
        list-style: none;
        -webkit-padding-start: 0;
    }
    .col-sm-4{
        margin-bottom: 20px;
    }
    .h1 {
        margin-bottom: 30px;
    }
    @media (min-width: @screen-sm-min) {
        padding-top: 70px;
        padding-bottom: 70px;
    }
}
.claim_plate{
    position: relative;
    margin: 0;
    z-index: 980;
    color: white;
    background-color: @primary;
    padding: 25px;
    padding-top: 30px;
    @media (min-width: @screen-sm-min) {
        height: 100%;
        padding: 55px;
    }
}
.plate-logo{
    max-width: 300px;
    margin-bottom: 16px;
}
.claim{
    position: relative;
    margin-top: 20px;
    z-index: 1000;
    font-weight: 200;
}
.claim p{
    display: inline-block;
}
.claim_link{
    position: relative;
    color: white;
    display: inline;
}
a.claim_link:hover, a.claim_link:active, a.claim_link:visited, a.claim_link:focus{
    color: white;
}
.claim_link img{
    margin-left: 10px;
}
.startimage_container{
    position: relative;
    overflow: hidden;
    height: 150px;
    padding: 0;
    @media (min-width: @screen-sm-min) {
        height: 100%;
    }
}
.startimage {
    background-image: url('../../img/startimage.jpg');
    width: 100%;
    height: 150px;
    background-position: right;
    background-size: cover;
    overflow: hidden;
    margin: auto;
    @media (min-width: @screen-sm-min) {
        height: 100%;
    }
}

/* SERVICES ---------------------------------------------------------------------------*/
.services{
    position: relative;
    margin-bottom: 60px;
    text-align: left;
}
.services1, .services2, .services3{
    padding-right: 20px;
    padding-bottom: 40px;
}
.services .col-lg-4 p{
    max-width: 340px;
    padding-left: 67px;
}
.services3{
    padding-bottom: 20px;
}
.services h2{
    margin-bottom: 18px;
    margin-top: 16px;
    display: inline;
    vertical-align: middle;
    padding-left: 12px;
}
/* Services gr��er 768 --------------------------------------------------------*/
@media (min-width: @screen-sm-min){
    .services h2{
    	display: block; 
    	padding-left: 4px;
    }
    .services .col-lg-4 p{
	   padding-left: 4px;
    }
}
/* Services 990er GRID--------------------------------------------------------*/
@media (min-width: @screen-md-min){
    .services1, .services2, .services3{
	padding-right: 30px;
    }
}
/* Services 1200er GRID--------------------------------------------------------*/
@media (min-width: @screen-lg-min){
       .services {
    	padding-left: 60px;
    	padding-right: 40px;
    	margin-top: 55px;
    	margin-bottom: 58px;
    }
}
.circle1, .circle2, .circle3{
    display: inline;
}
.circle1 img, .circle2 img, .circle3 img{
    border-radius: 50%;
    height: 50px;
    width: 50px;
}
    
.circle1 img{
    background-color: @secondary-blue;
}
.circle2 img{
    background-color: @secondary-green;
}
.circle3 img{
    background-color: @secondary-purple;
}
.services-1 h2{
    color: @secondary-blue-type;
}
.services-2 h2{
    color: @secondary-green-type;
}
.services-3 h2{
    color: @secondary-purple-type;
}
@media (min-width: @screen-sm-min){
    .services img, .circle1, .circle2, .circle3{
	width: 100px;
        height: 100px;
        display: block;
    }
    .services h3{
        display: block;
    }
}

.divider{
    border-bottom: 2px solid #91919a;
    margin-top: 22px;
    margin-bottom: 20px;
    display: inline-block;
    width: 34%;
    @media (max-width: @screen-xs-max) {
        width: 10%;
    }
}
.divider-container{
    margin-top: 55px;
}
