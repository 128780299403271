.container-fluid{
    padding-left: 15px;
    padding-right: 15px;
    @media (min-width: @screen-sm-min) {
        padding-left: 50px;
        padding-right: 50px;
    }
}

body{
    font-family: @font-family-base;
    font-weight: 300;
    height: auto;
    width: auto;
    font-size: @font-size-base;
    color: #282835;
    line-height: 1.5;
}
h1, .h1 { font-weight: 400; line-height: 1.3; }
h2, h3, .h2, .h3{ font-weight: 400; line-height: 1.3;}
h4, h5, h6, .h4, .h5, .h6{ font-weight: 300; line-height: 1.3;}


.bold{font-weight: 400;}
.bold-inline{font-weight: 400; display: inline;}

h1, .h1{
    font-size: 26px;
}
h2, .h2{
    font-size: 19px;
}
h3, .h3{
    font-size: 16px;
}
h4, .h4{
    font-size: 16px;
}
h5, .h5{
    font-size: 15px;
}
h6, .h6{
    font-size: 14px;
}
strong{
    font-weight: 600;
}
.h-strong{
    font-weight: 700;
}
.p-large{
    font-size: 21px;
}
.p-x-large{
  font-size: 24px;
}
.p-small{
    font-size: 14px;
}

@media (min-width: 768px) {
    h1, .h1{
        font-size: 31px;
    }
    h2, .h2{
        font-size: 20px;
    }
    h3, .h3{
        font-size: 16px;
    }
    h4, .h4{
        font-size: 16px;
    }
    h5, .h5{
        font-size: 15px;
    }
    h6, .h6{
        font-size: 14px;
    }
}
@media (min-width: 992px) {
    h1, .h1{
        font-size: 36px;
    }
    h2, .h2{
        font-size: 24px;
    }
    h3, .h3{
        font-size: 20px;
    }
    h4, .h4{
        font-size: 16px;
    }
    h5, .h5{
        font-size: 15px;
    }
    h6, .h6{
        font-size: 14px;
    }
}
@media (min-width: 1200px) {
    h1, .h1{
        font-size: 36px;
    }
    h2, .h2{
        font-size: 24px;
    }
    h3, .h3{
        font-size: 20px;
    }
    h4, .h4{
        font-size: 16px;
    }
    h5, .h5{
        font-size: 15px;
    }
    h6, .h6{
        font-size: 14px;
    }
}

.text-uppercase {
    text-transform: uppercase;
}
.margin-top-10{
    margin-top: 10px;
}
.margin-top-20{
    margin-top: 20px;
}
.margin-top-50{
    margin-top: 50px;
}
.margin-top-80{
    margin-top: 80px;
}


/*Navbar*/
.navbar-default, .navbar-collapse, #navbar{
    border-color: #282835;
    background-color: white;
    font-weight: 400;
}
.navbar, .navbar-header, .navbar-brand{
    min-height: 80px;
}
.navbar-brand img{
    max-height: 50px;
}

li.active > a{
    border-bottom: 6px solid #282835;
}
#navbar > ul > li > a{
    &:active{
        border-bottom: 6px solid #282835;   
    }
    &:hover {
        border-bottom: 6px solid #282835;
    }
    &:focus{
        border-bottom: 6px solid #282835;
    }
    &:click {
        border-bottom: 6px solid #282835;
    }
}
#navbar > ul > li.active > a{
    background-color: white;
    color: #282835;
}
#navbar > ul > li > a{
    padding-top: 23.5px;
    color: #282835;
    min-height: 80px;
}
#navbar > ul > li{
    min-height: 80px;
}
.navbar-fixed-top{
    border-width: 0 0 2px;
}



.nabar-default, .navbar-toggle{
    border: none;
    margin: 0;
    min-height: 80px;
}
.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus{
    background-color: white;
}
.navbar-toggle .icon-bar{
    width: 24px;
    height: 3px;
}
.navbar-default .navbar-toggle .icon-bar{
    background-color: #282835;
}

.content {
    margin-top: 80px;
}
.image-tag{
    height: 45px;
    padding-top: 6px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 21px;
    color: #282835;
}
.btn-image-tag {
    display: inline-block;
    height: 45px;
    width: 100%;
    padding-top: 6px;
    margin-bottom: 20px;
    font-weight: 400;
    font-size: 21px;
    color: #282835;
    background-color: #d9d9d9;
    opacity: 0.8;
    border-radius: 0;
}
/*.btn-image-tag:hover{
    background-color: #d7d7d7;
    text-decoration: none;
    opacity: 1;
}*/




.btn-base-ci, .btn-light, .inactive-btn{
    opacity: 0.8;
    color: white;
    border-radius: 0;
    padding: 10px 20px 10px 20px;
    font-weight: 400;
}
.btn-base-ci:hover, btn.light:hover{
    opacity: 0.9;
    color: white;
}
.btn-base-ci, .inactive-btn {
    background-color: #232336;
}
.btn-base-ci:hover {
    background-color: #232336;
}
.btn-light {
    background-color: #d7d7d7;
    color: #282835;
}
.btn-light:hover {
    background-color: #d7d7d7;
}

.inactive-btn {
    display: inline-block;
    width: 30%;
    @media (max-width: @screen-xs-max) {
        width: 70%;
    }
}


.btn-next{
    width: 100%;
    text-align: right;
}
.btn-back {
    width: 100%;
    text-align: left;
    color: #282835;
    background-color: #d7d7d7;
}
.btn-back:hover{
    background-color: #d7d7d7;
    opacity: 1;
    color: #282835;
}

.teaser-light{
    background-color: #f2f2f2;
    padding-bottom: 45px;
    font-weight: 400;
}
.teaser-dark{
    background-color: #232336;
    padding-bottom: 45px;
    font-weight: 400;
    color: #e6e6e6;
}
.footer{
    background-color: #14141b;
    padding-top: 20px;
    padding-bottom: 6px;
    color: #e6e6e6;
    a {
        color: #e6e6e6;
        &:visited {
            color: darken(#e6e6e6, 15%);
        }
        &:hover {
            color: #e6e6e6;
        }
        &:active {
            color: darken(#e6e6e6, 15%);
        }
        &:focus {
            color: darken(#e6e6e6, 15%);
        }
    }
}

